<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8 col-sm-12">
          <FormElements
            :label="$t('view.company_info')"
            :labelTitle="$t('view.company_name')"
            :labelBody="$t('view.company_about')"
            :labelAddress="$t('view.address')"
            :hasTitle="true"
            :title_ar="row.name_ar"
            :title_en="row.name_en"
            :hasBody="true"
            :body_ar="row.about_ar"
            :body_en="row.about_en"
            :hasAddress="true"
            :address_ar="row.address_ar"
            :address_en="row.address_en"
            v-on:formChange="handleFormChange"
          >
          </FormElements>

          <FormSingleLang
            :label="$t('view.details')"
            :labelEmail="$t('view.email')"
            :labelCivilID="$t('view.civil_id')"
            :labelMobile="$t('view.mobile')"
            :labelPassword="$t('view.password')"
            :labelUsername="$t('view.username')"
            :hasUsername="true"
            :disableUserName="disableUsername"
            :username="row.username"
            :hasEmail="true"
            :email="row.email"
            :hasCivilID="true"
            :civil_id="row.civil_id"
            :hasMobile="true"
            :mobile="row.mobile"
            :mobile_country_code="row.country_code"
            :hasWhatsApp="true"
            :whatsapp="row.whatsapp"
            :whatsapp_country_code="row.whatsapp_country_code"
            :hasPassword="true"
            :password="row.password"
            v-on:formChange="handleDetailChange"
          >
          </FormSingleLang>
        </div>

        <div v-if="!pgLoading" class="col-4 col-sm-12">
          <FormPayment
            v-if="auth.role == 'root'"
            :label="$t('view.paymentMethod_info')"
            :labelKey="$t('view.key')"
            :labelMerchantID="$t('view.merchantID')"
            :labelUsername="$t('view.username')"
            :labelPassword="$t('view.password')"
            :labelCurrency="$t('view.currency')"
            :payment_method_id="row.payment.payment_method_id"
            :merchant_id="row.payment.merchant_id"
            :username="row.payment.username"
            :password="row.payment.password"
            :_key="row.payment.key"
            :currency="row.payment.currency"
            @formChange="handleFormPaymentChange"
          />

          <CardImage
            :labelImage="$t('view.logo')"
            :image_base64="row.image_base64"
            :image_preview="row.image_preview"
            v-on:imageChange="handleImageChange"
          >
          </CardImage>

          <CardStatus
            v-if="auth.role == 'root'"
            :status="row.status"
            v-on:statusChange="handleStatusChange"
          >
          </CardStatus>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import FormPayment from "@/components/FormPayment";
import Loading from "@/components/Loading";
import FormElements from "@/components/FormElements";
import FormSingleLang from "@/components/FormSingleLang";
import CardImage from "@/components/CardImage";
import CardStatus from "@/components/CardStatus";
import Buttons from "@/components/Buttons";
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "EditOrNew",
  components: {
    FormPayment,
    Loading,
    FormElements,
    FormSingleLang,
    CardImage,
    CardStatus,
    Buttons,
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        id_token: "",
      },

      // row
      row: {
        name_ar: "",
        name_en: "",

        about_ar: "",
        about_en: "",

        address_ar: "",
        address_en: "",

        image_base64: "",
        image_preview: "",

        email: "",
        username: "",
        civil_id: "",
        mobile: "",
        whatsapp_country_code: "",
        whatsapp: "",
        country_code: "",
        password: "",

        payment: {
          payment_method_id: "",
          merchant_id: "",
          username: "",
          password: "",
          key: "",
          currency: "",
        },

        status: 1,
      },
      company_id: null,
      msgCurrent: null,
      btnCurrent: null,
      methodType: null,
      pgLoading: null,
      pgCurrent: null,
      btnLoading: false,
      disableUsername: false,
      refs: "companies",
    };
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }

    if (this.$route.params.id) {
      this.company_id = this.$route.params.id;
    } else {
      this.company_id = ApiService.getUser().company.encrypt_id;
    }

    this.msgCurrent = this.company_id ? "Updated" : "Created";
    this.btnCurrent = this.company_id ? "Update" : "Create";
    this.methodType = this.company_id ? "PUT" : "POST";
    this.pgLoading = !!this.company_id;
    this.pgCurrent = this.company_id ? "Edit" : "Add New";

    if (this.company_id) this.fetchRow();

    setCurrentPageTitle(this.$t("nav.companies"), "bx bxs-user-rectangle");
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/" + this.company_id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.image_preview = res.data.row.image
            ? res.data.row.image.url
            : "";
          this.row.image_base64 = res.data.row.image
            ? res.data.row.image.url
            : "";

          this.row.name_ar = res.data.row.ar.name;
          this.row.name_en = res.data.row.en.name;

          this.row.payment = res.data.row.payment;

          this.row.about_ar = res.data.row.ar.about;
          this.row.about_en = res.data.row.en.about;

          this.row.address_ar = res.data.row.ar.address;
          this.row.address_en = res.data.row.en.address;

          this.row.status = res.data.row.status;
          this.row.email = res.data.row.email;
          this.row.username = res.data.row.username;
          this.row.civil_id = res.data.row.civil_id;
          this.row.mobile = res.data.row.phone;
          this.row.country_code = res.data.row.country_code;

          this.row.whatsapp = res.data.row.whatsapp;
          this.row.whatsapp_country_code = res.data.row.whatsapp_country_code;
          if (this.row.username) this.disableUsername = true;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const config = { headers: { "Content-Type": "multipart/form-data" } };

      const options = {
        url: this.company_id ? this.refs + "/" + this.company_id : this.refs,
        method: this.methodType,
        data: {
          ar: {
            name: this.row.name_ar,
            about: this.row.about_ar,
            address: this.row.address_ar,
          },

          en: {
            name: this.row.name_en,
            about: this.row.about_en,
            address: this.row.address_en,
          },

          payment: this.row.payment,
          email: this.row.email,
          username: this.row.username,
          civil_id: this.row.civil_id,
          phone: this.row.mobile,
          country_code: this.row.country_code,
          whatsapp_country_code: this.row.whatsapp_country_code,
          whatsapp: this.row.whatsapp,
          password: this.row.password,
          image_base64: this.row.image_base64,
          status: this.row.status,
        },
      };
      this.axios(options, config)
        .then((res) => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message:
              this.btnCurrent == "Update"
                ? "تم التعديل بنجاح"
                : "تم الإضافة بنجاح",
          });

          if (this.auth.role == "root") this.$router.push({ name: this.refs });
          else {
            // LocalStorage
            localStorage.setItem("encrypt_id", res.data.data.encrypt_id);
            localStorage.setItem("avatar", res.data.data.avatar);
            localStorage.setItem("username", res.data.data.name);
            localStorage.setItem("role", res.data.data.role);
            localStorage.setItem("currency", res.data.data.company.currency);
            localStorage.setItem(
              "company_id",
              res.data.data.company.company_id
            );
            localStorage.setItem(
              "company_encrypt_id",
              res.data.data.company.encrypt_id
            );

            window.location.reload();
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },

    // handel Changes from child components
    handleFormChange(event) {
      this.row.name_ar = event.title_ar;
      this.row.name_en = event.title_en;

      this.row.about_ar = event.body_ar;
      this.row.about_en = event.body_en;

      this.row.address_ar = event.address_ar;
      this.row.address_en = event.address_en;
    },

    // handel Changes from child components
    handleDetailChange(event) {
      this.row.email = event.email;
      this.row.username = event.username;
      this.row.civil_id = event.civil_id;
      this.row.mobile = event.mobile;
      this.row.whatsapp = event.whatsapp;
      this.row.country_code = event.mobile_country_code;
      this.row.whatsapp_country_code = event.whatsapp_country_code;
      this.row.password = event.password;
    },

    handleFormPaymentChange(event) {
      this.row.payment.key = event.key;
      this.row.payment.username = event.username;
      this.row.payment.password = event.password;
      this.row.payment.merchant_id = event.merchant_id;
      this.row.payment.currency = event.currency;
      this.row.payment.payment_method_id = event.payment_method_id;
    },

    handleImageChange(event) {
      this.row.image_base64 = event.image_base64;
    },
    handleStatusChange(event) {
      this.row.status = event.status;
    },
  },
});
</script>
