<template>
  <div class="">
    <CardSelect
      :label="$t('view.paymentMethod_title')"
      :select_id="row.payment_method_id"
      refs="payment-methods"
      @selectChange="handlePaymentMethod"
    />
    <div class="card" style="margin-top: 0">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{ label }}
        </h4>

        <div class="row">
          <!-- Provider -->
          <div class="form-group col-12">
            <label
              for="input1"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
            >
              {{ labelKey }}
            </label>
            <input
              id="input1"
              type="text"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              v-model="row.key"
              v-on:input="onFormChange"
            />
          </div>
          <!-- End Provider -->

          <!-- Provider -->
          <div class="form-group col-12">
            <label
              for="input1"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
            >
              {{ labelMerchantID }}
            </label>
            <input
              id="input1"
              type="text"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              v-model="row.merchant_id"
              v-on:input="onFormChange"
            />
          </div>
          <!-- End Provider -->

          <!-- Name -->
          <div class="form-group col-12" v-if="row.payment_method_id == 1">
            <label
              for="input3"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
            >
              {{ labelUsername }}
            </label>
            <input
              id="input3"
              type="text"
              autocomplete="off"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              v-model="row.username"
              v-on:input="onFormChange"
              required=""
            />
          </div>
          <!-- End Name -->

          <!-- Password -->
          <div class="form-group col-12" v-if="row.payment_method_id == 1">
            <label
              for="input5"
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
            >
              {{ labelPassword }}
            </label>
            <input
              id="input5"
              type="text"
              autocomplete="off"
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              v-model="row.password"
              v-on:input="onFormChange"
              :placeholder="'****************'"
            />
          </div>
          <!-- End Password -->

          <!-- Country Code -->
          <div class="form-group col-12">
            <label
              class="col-form-label"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : 'float-left'"
            >
              {{ labelCurrency }}
            </label>

            <select
              class="form-control"
              :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
              v-model="row.currency"
              @change="onFormChange"
            >
              <option value="USD">
                {{ $t("app.usd") }}
              </option>
              <option value="KWD">
                {{ $t("app.kwd") }}
              </option>
              <option value="EGP">
                {{ $t("app.egp") }}
              </option>
            </select>
          </div>
          <!-- End Currency -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardSelect from "@/components/CardSelect";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormPayment",
  components: {
    CardSelect,
  },
  props: [
    "label",
    "labelMerchantID",
    "labelUsername",
    "labelPassword",
    "labelKey",
    "labelCurrency",

    "payment_method_id",
    "merchant_id",
    "username",
    "password",
    "_key",
    "currency",
  ],
  data() {
    return {
      row: {
        payment_method_id: this.$props.payment_method_id,
        merchant_id: this.$props.merchant_id,
        username: this.$props.username,
        password: this.$props.password,
        key: this.$props._key,
        currency: this.$props.currency,
      },

      editor: {
        api_key: window.editor_apiKey,
        menubar: window.editor_menubar,
        plugins: window.editor_plugins,
        toolbar: window.editor_toolbar,
      },
    };
  },
  methods: {
    onFormChange() {
      this.$emit("formChange", this.row);
    },
    handlePaymentMethod(event) {
      this.row.payment_method_id = event.select_id;
      this.onFormChange();
    },
  },
});
</script>
