<template>
  <div class="card">
    <div class="card-body">
      <label
        class="col-form-label"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ label }}
      </label>

      <div class="col-md-12 text-center" style="padding: 0">
        <span
          v-if="dataLoading"
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        >
        </span>
        <select
          v-if="!dataLoading"
          class="form-control"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
          v-model="row.select_id"
          v-on:change="onSelectChange"
        >
          <option v-for="(row, idx) in data" :key="idx" :value="row.id">
            {{ row[$i18n.locale]["title"] }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardSelect",
  props: ["label", "select_id", "category_id", "refs", "bus"],
  data() {
    return {
      auth: {
        role: "",
        id_token: "",
      },
      row: {
        select_id: this.$props.select_id,
      },
      parent_id: this.$props.category_id,
      data: [],
      dataLoading: true,
    };
  },
  created() {
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }

    if (this.bus != undefined) {
      this.bus.on("parent_changed", (parent_id) => {
        this.parent_id = parent_id;
        this.fetchData();
      });
    }
    this.fetchData();
  },
  methods: {
    onSelectChange() {
      const data = {
        select_id: this.row.select_id,
      };
      this.$emit("selectChange", data);
    },

    fetchData() {
      this.dataLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };

      const options = {
        url: this.refs,
        method: "GET",
        data: {},
        params: {
          category_id: this.parent_id,
          country_id: this.parent_id,
          government_id: this.parent_id,
          city_id: this.parent_id,
          except: this.except,
          // is_root: (this.auth.role == 'root') ? true : false,
          paginate: 1000,
        },
      };
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.data = res.data.data.rows;
        })
        .catch((err) => {
          this.dataLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
  },
});
</script>
